
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonLabel,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import settingsApp from "@/libs//settings";
import httpApp from "@/libs/http";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import { useLocalStorage } from "@/libs/localStorage";
import { useStore } from "@/store";
import MainHeader from "@/components/MainHeader.vue";
import VendorNavComponent from "./VendorNavComponent.vue";
import MainFooter from "@/components/MainFooter.vue";

export default defineComponent({
  name: "Vendor Details",
  setup() {
    const { t, tm, te } = useI18n();
    const storeX = useStore();
    const router = useRouter();
    const { createLoading, openToast } = useGlobalFunctions();
    const { getLocalStorageData } = useLocalStorage();
    const vendorFieldsData = ref(null);
    const isvendor = ref(false);
    const isApproved = ref(false);

    const enableEditVendorDetails = getLocalStorageData(
      "editVendorDetailsEnabled"
    );

    const saveVendorAction = async (event) => {
      event.preventDefault();
      const loading = await createLoading(t("pleaseWait"));
      loading.present();

      const form = document.querySelector("#vendorForm") as HTMLFormElement;
      const data = new FormData(form);
      httpApp
        .sendPostFormData(settingsApp.getEndpointUrl("saveVendor"), data)
        .then(async (response: any) => {
          if (response.success) {
            openToast(t("successMsgs.successEditSellerAccount"), "success");
            loading.dismiss();
            settingsApp.getVendorData();
          } else {
            if (te("apiMessages.codes." + response.error.code)) {
              openToast(
                t("apiMessages.codes." + response.error.code),
                "danger"
              );
              loading.dismiss();
            } else {
              openToast(response.error.message, "danger");
              loading.dismiss();
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
          openToast(error.message, "danger");
          loading.dismiss();
        });
    };

    const isEnableText = ref("");

    const getIsEnableText = (value) => {
      if (value == 1) {
        isEnableText.value = t("yes");
      } else {
        isEnableText.value = t("no");
      }
    };

    function ionViewWillEnter() {
      if (storeX.state.sellerData) {
        vendorFieldsData.value = storeX.state.sellerData.vendor_data;
        isvendor.value = storeX.state.sellerData.is_vendor;
        isApproved.value = storeX.state.sellerData.is_approved;
        if (storeX.state.sellerData.vendor_data.is_enabled) {
          getIsEnableText(storeX.state.sellerData.vendor_data.is_enabled.value);
        }
      } else {
        vendorFieldsData.value = null;
        isvendor.value = false;
        isApproved.value = false;
      }
    }

    return {
      t,
      tm,
      storeX,
      isvendor,
      isApproved,
      enableEditVendorDetails,
      ionViewWillEnter,
      vendorFieldsData,
      saveVendorAction,
      getIsEnableText,
      isEnableText,
    };
  },
  components: {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonLabel,
    MainHeader,
    VendorNavComponent,
    MainFooter,
  },
});
